<template>
    <div>
        <v-col cols="12" sm="4">
            <h4>Consultas con Seguimiento</h4>
        </v-col>
        <v-row align="center" class="list px-3 mx-auto">
            <v-col cols="12" sm="8">
                <v-text-field disabled v-model="searchConsulta" label="Busqueda aun no implementada"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-btn disabled @click="page = 1; retrieveConsulta();">
                    Search
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
                <v-row>
                    <v-col cols="4" sm="3">
                        <v-select disabled v-model="pageSize" :items="pageSizes" label="Consultas por Pagina"
                            @change="handlePageSizeChange"></v-select>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-pagination v-model="page" :length="totalPages" total-visible="7" next-icon="mdi-menu-right"
                            prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                    <v-card-title>Consultas con Seguimiento</v-card-title>
                    <v-data-table :headers="headers" :items="consultas" disable-pagination :hide-default-footer="true"
                        :custom-sort="customSort">
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ConsultaDataService from "../services/ConsultaDataService";


export default {

    name: "consultas",
    components: {

    },
    data() {
        return {
            dialog: false,
            searchConsulta: "",
            consultas: [],
            headers: [{
                text: "Nombre Paciente",
                sortable: true,
                value: "pacienteNombre"
            }, {
                text: "Fecha de Consulta",
                align: "start",
                sortable: true,
                value: "fechaConsulta"
            },
            {
                text: "Notas",
                value: "notasConsulta",
                sortable: true
            },
            {
                text: "Numero de Contacto",
                value: "pacienteTelefono",
                sortable: false
            },
            ],

            page: 1,
            totalPages: 0,
            pageSize: 12,
            pageSizes: [12, 24, 48],
        };
    },
    methods: {


        //customSort: the native sort function of the data view table doesn't works as it should, this custom implementation solves it
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == 'date') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                } else {
                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        } else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
                console.log(items);
            });
            return items;

        },

        getRequestParams(searchConsulta, page, pageSize) {
            let params = {};
            if (searchConsulta) {
                params["idPacienteConsulta"] = searchConsulta;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
            return params;
        },

        retrieveConsulta() {

            const params = this.getRequestParams(
                this.searchConsulta,
                this.page,
                this.pageSize
            );

            ConsultaDataService.getAllSeguimiento(params)
                .then((response) => {
                    const {
                        consultas,
                        totalPages
                    } = response.data;
                    this.consultas = consultas.map(this.getDisplayConsulta);
                    this.totalPages = totalPages;

                })
                .catch((e) => {
                    console.log(e);
                });

        },
        getDisplayConsulta(consulta) {
            return {
                idPacienteConsulta: consulta.idPacienteConsulta,
                fechaConsulta: consulta.fechaConsulta,
                notasConsulta: consulta.notasConsulta.substr(0, 40),
                seguimientoConsulta: consulta.seguimientoConsulta,
                pacienteNombre: consulta.pacienteInfo[0].nombre + " " + consulta.pacienteInfo[0].apellido,
                pacienteTelefono: consulta.pacienteInfo[0].telefono,
            };
        },

        handlePageChange(value) {
            this.page = value;
            this.retrieveConsulta();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.retrieveConsulta();
        },
        refreshList() {
            this.retrieveConsulta();
        },

    },
    mounted() {
        this.retrieveConsulta();
    },
};
</script>
